import {useEffect, useMemo, useState} from "react";
import {dbApi} from "../services/firebase";
import {Link, useParams} from "react-router-dom";
import {Card, Col, Container, Row} from "react-bootstrap";
import {FaLaptop} from "react-icons/fa";
import * as PropTypes from "prop-types";

function ProfileLinksComponent(props) {
    const { presenter } = props;
    

    return (
        <Container style={{maxWidth:"75%"}}>
            {presenter.twitter &&
                <div style={{marginTop: "1em"}}>
                         <img
                          style={{ marginRight: "0.2em", maxWidth: "1.5em"}}
                          src="/x.svg"
                          alt=" x (twitter) logo"
                        />
                    <a rel="noreferrer" target={"_blank"} style={{textDecoration: "none"}}
                       href={"https://twitter.com/" + presenter.twitter.replaceAll("@", "")}>
                        <div className={"d-inline"}>@</div>
                        <div className={"d-inline"} style={{textDecoration: "underline"}}>
                            {presenter.twitter.replaceAll("@", "")}
                        </div>
                    </a>
                </div>}

            {presenter.linkedin &&
                <div style={{marginTop: "1em"}}>
                    <img style={{marginRight: "0.2em"}} src="/linkedin.svg" alt="linkedin logo"/>
                    <a rel="noreferrer" target={"_blank"} style={{textDecoration: "none"}}
                       href={"https://linkedin.com/in/" + presenter.linkedin}>
                        <div className={"d-inline"}></div>
                        <div className={"d-inline"} style={{textDecoration: "underline"}}>{presenter.linkedin}</div>
                    </a>
                </div>
            }

            {presenter.website &&
                <a target={"_blank"}
                   href={presenter.website.startsWith("http") ? presenter.website : 'http://' + presenter.website}
                   rel="noreferrer">
                    <img style={{marginRight: "0.4em"}} src="/globe.svg" alt="website icon"/>
                    {presenter.website}
                </a>
            }
        </Container>
    )
}

ProfileLinksComponent.propTypes = {presenter: PropTypes.any};

export function PublicSessionPage() {
    // ID of session (from url)
    const {id} = useParams();

    const [session, setSession] = useState(undefined);

    // Presenters of session
    const [presenters, setPresenters] = useState(undefined);

    const [experienceState, setExperienceState] = useState("");


    // Set experience state
    useEffect(() => {
        dbApi.getExperienceState().then((promisedExperienceState) => setExperienceState(promisedExperienceState));
      }, []);
    
    // Load session
    useEffect(() => {
        dbApi.getSession(id).then(setSession)
    }, []);

    // Load presenters
    useEffect(() => {
        if (session && !presenters) {
            dbApi.getPresentersByIds(session?.presenters).then(setPresenters);
        }
    }, [presenters, session]);

    if (session && presenters) return (
        <Card className={"mt-3"}>
            <TopicColor topic={session.topic}>
                <h1 className={"d-inline"}>{session.title}</h1>

                <h2>{session.subtitle}</h2>

                {session.laptopsRequired === true &&
                    <FaLaptop style={{
                        right: "1vw",
                        top: ".7em",
                        position: "absolute",
                        fontSize: "400%"
                    }}/>
                }

                <hr/>

                {/* Presenters */}
                <div style={{fontSize: "2rem"}}>
                    {/* First presenter */}
                    {(presenters[0]?.firstName ?  presenters[0]?.firstName : "no presenter assigned yet"  ) + ' ' + (presenters[0]?.lastName ?  presenters[0]?.lastName : ""  )}
                    {/* Second presenter */}
                    {presenters[1] &&
                        <>
                            <div className={"d-inline"}>{" & "}</div>
                            {presenters[1]?.firstName + ' ' + presenters[1]?.lastName}
                        </>
                    }
                </div>

                <hr/>

                {session.topic &&
                    <h3>{session.topic}</h3>
                }
            </TopicColor>

            <Card.Body>
                <Container>
                    <div><h5>Short description</h5>
                        {session.shortDescription ? session.shortDescription.split("%;;;%").map((i, index) =>
                            <div key={i + index}>{i}</div>) : ''}
                    </div>

                    <br/>

                    <Card>
                        <Card.Header className={"text-center"}>
                            <h1 className={"d-inline"}>Extra information</h1>
                        </Card.Header>

                        <Card.Body style={{display: "block", paddingTop: 0, paddingBottom: 0}}>
                            {session.topic &&
                                <Row>
                                    <Col sm={3}>
                                        Topic:
                                    </Col>
                                    <Col>
                                        {session.topic}
                                    </Col>
                                </Row>
                            }
                            
                            {session.intendedAudience &&
                                <Row>
                                    <Col sm={3}>
                                        Intended audience:
                                    </Col>
                                    <Col>
                                        {session.intendedAudience}
                                    </Col>
                                </Row>
                            }

                            {session.sessionGoal &&
                                <Row>
                                    <Col sm={3}>
                                        Goal of the session:
                                    </Col>
                                    <Col>
                                        {session.sessionGoal}
                                    </Col>
                                </Row>
                            }

                            {session.experienceLevel && experienceState &&
                                <Row>
                                    <Col sm={3}>
                                        Expected experience level:
                                    </Col>

                                    <Col>
                                        {session.experienceLevel}
                                    </Col>
                                </Row>
                            }

                            {session.maxParticipants &&
                                <Row>
                                    <Col sm={3}>
                                        Maximum participants:
                                    </Col>

                                    <Col>
                                        {session.maxParticipants}
                                    </Col>
                                </Row>
                            }
                             {session.materialUrl && session.state === "Confirmed" && (
                                 <Row>
                                <Col sm={3}>Material Url:</Col>
                                 <Col><a target="_blank" href={session.materialUrl} rel="noreferrer">{session.materialUrl}</a></Col>
                                </Row>
                              )}
                        </Card.Body>
                    </Card>

                    <br/>
                    <br/>
                    <br/>

                    <div style={{ display: "grid", gridAutoFlow: "column", gridAutoColumns: "1fr", gap: "1em", width: "50%", margin: "auto" }}>
                    {presenters[0] &&
                        <Card>
                            <TopicColor className={"text-center"}>
                                <h1>{presenters[0].firstName + ' ' + presenters[0].lastName}</h1>
                                {presenters[0].profilePicture &&
                                    <div>
                                        <img className={"rounded mx-auto d-block"}
                                             width={"100%"}
                                             src={presenters[0].profilePicture}
                                             alt={"presenter"}
                                        />
                                    </div>
                                }
                            </TopicColor>

                            <Card.Body>
                                {presenters[0].bio}

                                <hr/>

                                <ProfileLinksComponent presenter={presenters[0]}/>
                            </Card.Body>
                            </Card>
                        }

                        {presenters[1] &&
                            <Card>
                                <TopicColor className={"text-center"}>
                                    <h1 className={"d-inline"}>{presenters[1].firstName + ' ' + presenters[1].lastName}</h1>
                                    {presenters[1].profilePicture &&
                                        <div>
                                            <img className={"rounded mx-auto d-block"}
                                                 width={"100%"}
                                                 src={presenters[1].profilePicture}
                                                 alt={"presenter"}
                                            />
                                        </div>
                                    }
                                </TopicColor>

                                <Card.Body>
                                    {presenters[1].bio}
                                </Card.Body>
                            </Card>
                        }
                    </div>

                </Container>
            </Card.Body>
        </Card>
    )
}

function TopicColor(props) {
    const {children, topic} = props;
    if (topic === "Technology and Technique") {
        return <Card.Header className={"text-center"}
                            style={{backgroundColor: "#c7FFB1", color: "black"}}>{children}</Card.Header>
    }
    if (topic === "Process and Improvement") {
        return <Card.Header className={"text-center"}
                            style={{backgroundColor: "#FED28E", color: "black"}}>{children}</Card.Header>
    }
    if (topic === "Team and Individual") {
        return <Card.Header className={"text-center"}
                            style={{backgroundColor: "#FEC9DC", color: "black"}}>{children}</Card.Header>
    }
    if (topic === "Customer and Planning") {
        return <Card.Header className={"text-center"}
                            style={{backgroundColor: "#D6F9FF", color: "black"}}>{children}</Card.Header>
    }
    if (topic === "Intro's and Cases") {
        return <Card.Header className={"text-center"}
                            style={{backgroundColor: "#FFFF99", color: "black"}}>{children}</Card.Header>
    } else {
        return <Card.Header className={"text-center card-header-standard"}>{children}</Card.Header>
    }
}
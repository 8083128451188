export function SessionCard(props) {
  const { session } = props;
  return (
    <div className="card">
      <div className="card-header">
        <p className="card-title" style={{  fontSize : "10px" }}>
          <span className="border-end">{session.refNumber}</span> {session.title}
        </p>
      </div>
      <div className="card-body" style={{  fontSize : "11px" }}>
        <div className="card-text">
          {session.shortDescription?.substring(0, 550).split('%;;;%').map((l, index) => (
            <p className="card-text" id={l + index} key={l + index}>
              {l}
            </p>
          ))}
        </div>
      </div>
      <div className="card-footer text-center m-0" style={{fontSize : "12px", padding:'0'}}>
        <ul style={{ padding: 0 }}>
          <li className="list-group-item">
            {session.presenters[1]?.firstName ? 'Presenters' : 'Presenter'}:{' '}
            {session.presenters[0] &&
            session.presenters[0].firstName &&
            session.presenters[0].lastName ? (
              <span>
                {session.presenters[0].firstName} {session.presenters[0].lastName}
              </span>
            ) : null}
            {/* Second presenter */}
            {session.presenters[1] &&
            session.presenters[1].firstName &&
            session.presenters[1].lastName ? (
              <span className="d-inline">
                {' '}
                & {session.presenters[1].firstName} {session.presenters[1].lastName}
              </span>
            ) : null}
          </li>
          <li className="list-group-item">Duration: {session.duration}</li>
        </ul>
      </div>
    </div>
  );
}
